@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./fonts/fonts.css";
body {
  margin: 0;
  font-family: "Circular Std", "Arial", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
}
.App {
  font-family: "Circular Std";
  box-sizing: border-box;
  background: #fff;
}

span {
  display: inline-block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Work-Sans {
  font-family: "Work Sans";
}

.MuiDialog-container .MuiPaper-root {
  margin: 10px;
}

.MuiFormHelperText-root {
  margin-left: 0px !important;
}

.mobile-only {
  display: none;
}

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

.gradient-text {
  background: linear-gradient(242.79deg, #ffb81c 0%, #5c068c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: inline-block;
}

.spin-button-none {
  -moz-appearance: textfield;
}

.hide-scroll {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}
