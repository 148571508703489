/* Circular Std */
@font-face {
  font-family: "Circular Std";
  src: url("CircularStd-Book.ttf") format("trueType");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std";
  src: url("CircularStd-Medium.ttf") format("trueType");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std";
  src: url("CircularStd-Bold.ttf") format("trueType");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Circular Std";
  src: url("CircularStd-Black.ttf") format("trueType");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

/* Montserrat */
@font-face {
  font-family: "Montserrat";
  src: url("Montserrat-Light.ttf") format("TrueType");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("Montserrat-Regular.ttf") format("TrueType");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("Montserrat-Medium.ttf") format("TrueType");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("Montserrat-SemiBold.ttf") format("TrueType");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("Montserrat-Bold.ttf") format("TrueType");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

/* Work Sans */
@font-face {
  font-family: "Work Sans";
  src: url("WorkSans-Regular.ttf") format("TrueType");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("WorkSans-Medium.ttf") format("TrueType");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("WorkSans-SemiBold.ttf") format("TrueType");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("WorkSans-Bold.ttf") format("TrueType");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("WorkSans-Black.ttf") format("TrueType");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

/* General Sans */
@font-face {
  font-family: "General Sans";
  src: url("GeneralSans-Extralight.ttf") format("TrueType");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "General Sans";
  src: url("GeneralSans-Light.ttf") format("TrueType");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "General Sans";
  src: url("GeneralSans-Regular.ttf") format("TrueType");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "General Sans";
  src: url("GeneralSans-Medium.ttf") format("TrueType");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "General Sans";
  src: url("GeneralSans-Semibold.ttf") format("TrueType");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "General Sans";
  src: url("GeneralSans-Bold.ttf") format("TrueType");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
